<template>

  <!-- Start Content-->
  <div>
    <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>
    
    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">

      <div class="form-inline md-form form-sm toolbar-search" v-if="viewParams.gType!='rent' && viewParams.gType!='building'">
        <div class="lang" v-html="lan.corp_name"></div>: &nbsp;<input class="form-control mr-1 w-200" type="text"  v-model="listStrage.query"  @keyup.enter="search">&nbsp;&nbsp;
        <div class="lang" v-html="lan.industry"></div>: &nbsp;<select class="form-control" v-model="industry" @change="search()">
          <option value="">{{lan.all}}</option>              
          <option v-for="(item,index) in industryData" :key="index" :value="index">{{item}}</option>
        </select>&nbsp;&nbsp;
        <div class="lang" v-html="lan.showState"></div>: &nbsp;<select class="form-control" v-model="stateID" @change="search()">
          <option value="">{{lan.all}}</option>              
          <option v-for="(item,d) in allstate" :key="d" :value="item.StateJa">{{item.StateJa}}</option>
        </select>&nbsp;&nbsp;
        <button type="button" class="btn btn-white" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
      </div>
        <div class="form-inline md-form form-sm toolbar-search" v-if="viewParams.gType=='rent' || viewParams.gType=='building'">
        ビル名: &nbsp;<input class="form-control mr-1 w-200" type="text"  v-model="BuildingName" >&nbsp;&nbsp;
        <span v-if="viewParams.gType=='rent'">
          <div class="lang" v-html="lan.corp_name"></div>: &nbsp;<input class="form-control mr-1 w-200" type="text"  v-model="CorpName" >&nbsp;&nbsp;
        <div class="lang" v-html="lan.usage"></div>: &nbsp;<select class="form-control" v-model="Usage" @change="search()">
          <option value="all">{{lan.all}}</option>              
          <option v-for="(item,index) in usage_array" :key="index" :value="item">{{item}}</option>
        </select>&nbsp;&nbsp;

        リース面積(坪): &nbsp;<input class="form-control mr-1 w-100" type="text"  v-model="TotalAreaFrom" >&nbsp;～&nbsp;<input class="form-control mr-1 w-100" type="text"  v-model="TotalAreaTo" >&nbsp;&nbsp;
        </span>
          
        <button type="button" class="btn btn-white" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button><div class="help">
          <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'bottom'" :help_text="lan.help_cbreai_building_filter"/>
          </div> 
      </div> 
      <div class="form-inline toolbar-download">
        <button type="button" class="btn btn-primary" v-if="viewParams.gType!='rent' && viewParams.gType!='building'" @click="listDownload">ダウンロード</button>
      </div>
    
      <div class="form-inline toolbar-display">
        
        <div class="mr-2 toolbar-display-count">
          <span class="mr-1">表示件数</span>
          <div>
            <select class="form-control mt-0" v-model.lazy="listStrage.limit" @change="search">
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>

       

      </div>
    </div>
    <div  v-if="viewParams.gType=='rent' || viewParams.gType=='building'">
  <div class="btn-group dropdown-btn-group box-btn">
         
          <button type="button" class="btn btn-primary waves-effect waves-light btn-back-fixed" @click="toggleDDMenu"><div class="lang" v-html="lan.showState"></div></button> 
        
          <transition name="fade" mode="out-in">
            <div class="ddmenu-modal" v-show="ddMenu">
              <div class="ddmenu-bg" @click="toggleDDMenu"></div>
              <div class="ddmenu">
                
                <div class="d-flex justify-content-center p-5" v-if="isLoading">
                  <div class="spinner-border m-12" role="status">
                    <div class="sr-only">Loading...</div>
                  </div>
                </div>
                <div v-else>
                  <button type="button" class="btn btn-danger waves-effect waves-light btn-dd-close" @click="toggleDDMenu"><i class="mdi mdi-close"></i></button>
                  <ul class="dropdown-menu show">
                    <li class="checkbox-row" v-for="(item,d) in allstate" :key="d">
                      <input type="checkbox" :id="'table-toggle-' + item.StateID" :value="item.StateID" v-model.lazy="StateIDS"> <label :for="'table-toggle-' + item.StateID">{{ item.StateJa }}</label>
                    </li>
                  </ul>
                  <div class="btn-group pull-right">
                    <button type="button" class="btn btn-primary" style="margin-right:10px" @click="confirm"> を選択します</button> 
                    <button type="button" class="btn btn-primary" style="margin-right:10px" @click="allCheck"><i class="mdi mdi-check-box-outline"></i> 全選択</button> 
                    <button type="button" class="btn btn-danger" @click="allUncheck"><i class="mdi mdi-checkbox-blank-off-outline"></i> 全解除</button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
    <div class=" has-options">
          <div  v-for="(tag, index) in StateIDS" :key="index" :data-value="tag">
                          {{stateName(tag)}}<a href="javascript:void(0)" class="remove" tabIndex="-1" :title="lan.remove" @click="removeTag(tag)">×</a>
                        
            </div>
          </div>
     </div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>
        表示項目が選択されていません。
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">

      <div class="table-rep-plugin">
        <div class="table-responsive">
          <table class="table table-striped focus-on">
            <thead>
              <tr>
                <th v-for="(tableHeader,key) in tableHeaders" :key="key" :class="'table-row-' + tableHeader">{{ tableHeader }} <div v-if="tableHeader=='空室率'"><div class="help">
                  <ctooltip :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'" :align="'right'" :help_text="lan.help_cbreai_building_vacancy_rate"/>
                  </div>
                  </div> </th>
                <th></th>
              </tr>
            </thead>
             <tbody v-if="jsonObj.count && checkKeys.length">
                <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)" @child="toggleFocus(idNum)">
                  
                  <list-table-row :row="obj" :checkList="checkKeys"  v-if="viewParams.gType=='rent' || viewParams.gType=='building'" />
                  <td v-show="viewParams.gType!='rent' && viewParams.gType!='building'" v-for="(obj2, idNum2) in checkKeys" :key="idNum2" ><span :title="obj[obj2]">{{obj2.indexOf('evaluation')>0 ?obj[obj2].toFixed(4)+'...':obj[obj2]}}</span></td>
                  <td class="table-focus-on-td">
                    <transition transition name="slide-btn" v-if="viewParams.gType!='rent' && viewParams.gType!='building'">
                      <router-link v-if="isFocus === idNum" :to="'/'+viewParams.name+'/'+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'?dataId='+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'&dataType='+viewParams.dataType+'&dataName='+obj['corp_name']+'&code='+(obj['code']==undefined?'':obj['code'])+'&dataNumber='+obj['corp_number']+'&curindex=tc1&tolink='+viewParams.tolink" class="btn btn-default">詳細 <i class="mdi mdi-arrow-right-bold"></i></router-link>
                    </transition>
                    <transition transition name="slide-btn" v-if="viewParams.gType=='rent'">
                      <router-link v-if="isFocus === idNum" :to="'/lease_info?BuildingID='+obj['BuildingID']+'&LeaseID='+obj['LeaseID']+'&tolink='+viewParams.tolink" class="btn btn-default">詳細 <i class="mdi mdi-arrow-right-bold"></i></router-link>
                    </transition>
                    <transition transition name="slide-btn" v-if="viewParams.gType=='building'">
                      <router-link v-if="isFocus === idNum" :to="'/build_info?BuildingID='+obj['BuildingID']+'&PropertyCode='+obj['PropertyCode']+'&tolink='+viewParams.tolink" class="btn btn-default">詳細 <i class="mdi mdi-arrow-right-bold"></i></router-link>
                    </transition>
                  </td>
                </tr>
              </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">表示データがありません。</code>
          </div>
        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->

      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1" v-if="jsonObj.total_count">
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('first')">«</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a></li>
        <li class="footable-page mb-1" :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }" v-for="num in maxPage" :key="num"><a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('next')">›</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('last')">»</a></li>
      </ul>
    </div> <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
      データ取得に失敗しました。
      </code>
    </div>
      
  </div> <!-- container -->

</template>

<script>
import ListTableRow from './ListTableRow.vue'
import axios from 'axios';
import { inject } from 'vue';
import Ctooltip from './Ctooltip';
export default {
  name: 'ListTable',
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      dataType: {
        type: String,
        default: '1',
      },
      gType: {
        type: String,
        default: '',
      },
      tolink: {
        type: String,
        default: '',
      },
      articleView: String,
      articleParamId: String,
    },
  },
  components: {
    ListTableRow,Ctooltip
  },
  data() {
    return {
      offset: 0,
      listStrage: {
        query: '',
        limit: 50,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      isLoading2: false,
      downloadAlert: '',
      BuildingName:'',
      BuildingID:'',
      isLoading3:false,
      CorpName:'',
      LeaseID:'',
      companyList:[],
      corpList:[],
      error_msg_corp:'',
      showcom_corp:false,
      showcom:false,
      StateIDS:[],
      stateID:'',
      cur_lan:"jp",
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      menu:[],
      allstate:[],
      TotalAreaFrom:'',
      TotalAreaTo:'',
      isPopup:false,
      isPopup2:false,
      Usage:'all',
      industry:'',
      industryData:[],
      usage_array:['事務所','ショ－ル－ム・展示場','倉庫','','注文用地','店舗','工場','物販店舗','飲食店舗','事務所兼店舗','事務所兼倉庫','住居','駐車場','教育施設・学校','貸地','自社使用','診療所・病院','バース','その他','サービス店舗','ホテル','電算室','貸会議室','コワーキングスペース'],
    }
  },
  methods: {
 
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
    allCheck: function() {
      var that=this;
      this.allstate.forEach(function(item){
       
       that.StateIDS.push(item.StateID);  
     })
     
    },
    stateName: function(stateID) {
     let a='';
     this.allstate.forEach(function(item){
       if(item.StateID==stateID){
        a=item.StateJa;
       }
     })
    
     return a;
    },
    removeTag(stateID){
      var that=this;
      this.StateIDS.forEach(function(item,index){
        if(item==stateID){
          that.StateIDS.splice(index,1);
          that.search();
        }
      })
    },
   
    allUncheck: function() {
      this.StateIDS = []
    },
     changeCom(item){
      this.BuildingName=item.BuildingName;
      this.BuildingID=item.BuildingID;
      this.comapnyList=[];
      this.showcom=false;
      this.search();
    },
     changeComCorp(item){
      this.CorpName=item.Name;
      this.LeaseID=item.LeaseID;
      this.corpList=[];
      this.showcom_corp=false;
      this.search();
    },
    
    search: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.gType + '_limit', this.listStrage.limit)
      sessionStorage.setItem(this.viewParams.gType + '_query', this.listStrage.query)
      sessionStorage.setItem(this.viewParams.gType + '_BuildingID', this.BuildingID)
      sessionStorage.setItem(this.viewParams.gType + '_BuildingName', this.BuildingName)
      sessionStorage.setItem(this.viewParams.gType + '_LeaseID', this.LeaseID)
      sessionStorage.setItem(this.viewParams.gType + '_CorpName', this.CorpName)
      sessionStorage.setItem(this.viewParams.gType + '_TotalAreaFrom', this.TotalAreaFrom)
      sessionStorage.setItem(this.viewParams.gType + '_TotalAreaTo', this.TotalAreaTo)
      sessionStorage.setItem(this.viewParams.gType + '_StateIDS', this.StateIDS.join(','))
      sessionStorage.setItem(this.viewParams.gType + '_paged', 1)
      localStorage.setItem('query',this.listStrage.query);
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            data_type: this.viewParams.gType, //表示类型
            q: this.listStrage.query, //キーワード
            industry:this.industry,//行业
            stateID:this.stateID,//state 
            CorpName: this.CorpName,
            Usage: this.Usage,
            BuildingName: this.BuildingName, 
            StateIDS:this.StateIDS.join(","),
            TotalAreaFrom:this.TotalAreaFrom,
            TotalAreaTo:this.TotalAreaTo,

            id_token:localStorage.getItem('id_token'),
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.industryData=this.jsonObj.industrys!=undefined ? this.jsonObj.industrys:[],
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    ltrim(str,char){
      return  str.substr(str.length-1,1)==char? str.substr(0,str.length-2):str;
    },
    confirm(){
      this.ddMenu=false;
      this.search();
    },
    getUserState:  function(){
      axios.get('/v1/getCbreaiSetting',{params:{email:localStorage.getItem('email'),id_token:localStorage.getItem('id_token')} })
      .then(response => {
          if(response.data.status==1){
           
            this.StateIDS=response.data.data.StateID!=undefined && response.data.data.StateID!=null ? this.ltrim(response.data.data.StateID,',').split(','):[];
            this.divisionIDS=response.data.data.divisionID!=undefined && response.data.data.divisionID!=null ? this.ltrim(response.data.data.divisionID,',').split(','):[];
           
          }
          
      })
      .catch(error => (
          console.log(error)
        )
      );

    },
    getAllstate(){
        axios.get('/v1/getAllState',{params:{id_token:localStorage.getItem('id_token')} })
      .then(response => {
          if(response.data.status==1){
             this.allstate=response.data.data!=undefined && response.data.data!=null ? response.data.data:[];
           
          }
          
      })
      .catch(error => (
          console.log(error)
        )
      );
    },
    pagenateNum: function(num) {
      this.isLoading = true
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            data_type: this.viewParams.gType, //表示类型
            q: this.listStrage.query, //キーワード
            CorpName: this.CorpName,
            industry:this.industry,
            stateID:this.stateID,//state 
            BuildingName: this.BuildingName, 
             StateIDS:this.StateIDS.join(","),
            TotalAreaFrom:this.TotalAreaFrom,
            TotalAreaTo:this.TotalAreaTo,
            id_token:localStorage.getItem('id_token'),
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
            sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateKey: function(key) {
      switch(key) {
        case 'next':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case 'prev':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case 'first':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case 'last':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log('Non-existent key')
      }
      if(this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              data_type: this.viewParams.gType, //表示类型
              q: this.listStrage.query, //キーワード
              industry:this.industry,
              stateID:this.stateID,//state 
              CorpName: this.CorpName,
              BuildingName: this.BuildingName, 
               StateIDS:this.StateIDS.join(","),
              TotalAreaFrom:this.TotalAreaFrom,
              TotalAreaTo:this.TotalAreaTo,
            id_token:localStorage.getItem('id_token'),
            }
          })
          .then(response => (
              this.jsonObj = response.data,
              this.tableHeaders = this.jsonObj.headers,
              this.listData = this.jsonObj.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
            }
          );
      }
    },
    pageHide: function(num) {
      if( num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4 ) {
        return true
      }
    },
    listDownload: function() {
      location.href=process.env.VUE_APP_API_URL_BASE+this.viewParams.apiUrl + 'download?data_type='+this.viewParams.gType+'&q='+ this.listStrage.query+'&stateID='+this.stateID+'&industry='+this.industry+'&id_token='+localStorage.getItem('id_token');
    }  
  },
  watch: {
    
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'], "*")
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },viewParams(){
      this.getAllstate();
      let language_data=JSON.parse(localStorage.getItem('language_data'));
      this.lan=language_data;
      this.getUserState();

      console.log('watch');
       console.log(this.viewParams);
        console.log(this.viewParams.gType+'<>'+this.viewParams.tolink);
      if( localStorage.getItem(this.viewParams.gType + '_limit') ) {
        this.listStrage.limit = localStorage.getItem(this.viewParams.gType + '_limit')
      }
      if( sessionStorage.getItem(this.viewParams.gType + '_query') ) {
        this.listStrage.query = sessionStorage.getItem(this.viewParams.gType + '_query')
      }
       if( sessionStorage.getItem(this.viewParams.gType + '_BuildingName') ) {
       
        this.BuildingName = sessionStorage.getItem(this.viewParams.gType + '_BuildingName')
      }
       if( sessionStorage.getItem(this.viewParams.gType + '_CorpName') ) {
  
        this.CorpName = sessionStorage.getItem(this.viewParams.gType + '_CorpName')
      }
      if( sessionStorage.getItem(this.viewParams.gType + '_TotalAreaTo') ) {
  
        this.TotalAreaTo = sessionStorage.getItem(this.viewParams.gType + '_TotalAreaTo')
      }
      if( sessionStorage.getItem(this.viewParams.gType + '_StateIDS') ) {
  
        this.StateIDS = sessionStorage.getItem(this.viewParams.gType + '_StateIDS').split(',')
      }
      if( sessionStorage.getItem(this.viewParams.gType + 'TotalAreaFrom') ) {
  
        this.TotalAreaFrom = sessionStorage.getItem(this.viewParams.gType + '_TotalAreaFrom')
      }

      if( sessionStorage.getItem(this.viewParams.namgTypee + '_paged') ) {
        this.listStrage.paged = Number(sessionStorage.getItem(this.viewParams.gType + '_paged'))
      }
      if( localStorage.getItem(this.viewParams.gType + '_tableHeaders') ) {
        //読み込み時点のlocalstrageのtableheadersを取得
        //let localTableHeaders = JSON.parse(localStorage.getItem(this.viewParams.name + '_tableHeaders'))

        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
              limit: this.listStrage.limit, //表示件数
              data_type: this.viewParams.gType, //表示类型
              
              q: this.listStrage.query, //キーワード
              CorpName: this.CorpName,
              industry:this.industry,
              BuildingName: this.BuildingName, 
               StateIDS:this.StateIDS.join(","),
              TotalAreaFrom:this.TotalAreaFrom,
              TotalAreaTo:this.TotalAreaTo,
              id_token:localStorage.getItem('id_token'),
            }
          })
          .then(response => {
              this.jsonObj = response.data
              this.tableHeaders = this.jsonObj.headers
              this.listData = this.jsonObj.data
              this.industryData=this.jsonObj.industrys!=undefined ? this.jsonObj.industrys:[];
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit)
              this.checkKeys = Object.keys(this.tableHeaders)[1]==1 ? this.tableHeaders:Object.keys(this.tableHeaders)
         console.log(this.industryData);
              // localstrageにないtableheaderをチェック済リストに追加
             // APIで取得した新しいtableheadersをlocalstrageに保存
              localStorage.setItem(this.viewParams.name + '_tableHeaders', JSON.stringify(this.tableHeaders))
              
              this.isLoading = false
            }
          )
          .catch(error => {
              console.log(error),
              this.jsonErr = true,
              this.isLoading = false
            }
          );
      } else {
        axios.get(this.viewParams.apiUrl, {
            params: {
              offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
              limit: this.listStrage.limit, //表示件数
              data_type: this.viewParams.gType, //表示类型
              q: this.listStrage.query, //キーワード
              CorpName: this.CorpName,
              industry:this.industry,
              BuildingName: this.BuildingName, 
               StateIDS:this.StateIDS.join(","),
            TotalAreaFrom:this.TotalAreaFrom,
            TotalAreaTo:this.TotalAreaTo,
              id_token:localStorage.getItem('id_token'),
            }
          })
          .then(response => (
              this.jsonObj = response.data,
              this.tableHeaders = this.jsonObj.headers,
              this.listData = response.data.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.checkKeys = Object.keys(this.tableHeaders)[1]==1 ? this.tableHeaders:Object.keys(this.tableHeaders),
              localStorage.setItem(this.viewParams.name + '_tableHeaders', JSON.stringify(this.tableHeaders)),
              this.industryData=this.jsonObj.industrys!=undefined ? this.jsonObj.industrys:[],
              this.isLoading = false
            )
          )
          .catch(error => {
              console.log(error),
              this.jsonErr = true,
              this.isLoading = false
            }
          );
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.help{position: relative;margin-left: 10px;    display: inline-block;}
.help-box{margin-top:10%;width: 60%;}
.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: .15rem;
  box-shadow: 0 .15rem .5rem rgba(0, 0, 0, .25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.tagbox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
.closes{position: absolute;
cursor: pointer;
        right: 16px;
    top: 4px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    padding: 2px 9px;
    border-radius: 15px;}
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 5.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 20%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.toolbar-search{position: relative;}
.company_box{  
    position: absolute;
    z-index: 999;
    top: 36px;
    left: 44px;
    max-height: 280px;
    overflow: scroll;
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    width: 300px;}
.company_box .cline{width: 98%;margin:0 1%; line-height: 25px; cursor: pointer; border-top:1px solid #ccc ;}
.company_box .cline:nth-child(1){border: 0;}
.company_box .cline:hover{color: #003f2d;}
.error{border:1px solid #ff0000}
.w-300{width: 300px;}
.w-100{width: 100px !important;}
.has-options ,.box-btn{float: left;}
.has-options div{padding: 5px; margin: 5px; display: table;float: left; border: 1px solid #eee; border-radius: 5px;}
</style>